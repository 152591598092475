const dev = {
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://api.linkmailer.pointless.cloud/dev"
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_56awJiAVe",
    APP_CLIENT_ID: "5cgo4r1maq986onr8kilttqimi",
    IDENTITY_POOL_ID: "eu-west-2:9f56a508-c94d-405c-b56d-2ba28778696e"
    
  },
  STRIPE_KEY: "pk_test_iHlOzpKZ00xvLmaK5VisiwVb00YvxyDFhZ"
};

const prod = {
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://api.linkmailer.pointless.cloud/prod"
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_GMwCZtyYB",
    APP_CLIENT_ID: "33dk6rdcvjccgv65qmp7tf6i4m",
    IDENTITY_POOL_ID: "eu-west-2:64c77c2e-cd08-43e2-a000-eba1f2f4d67f"
    
  },
  STRIPE_KEY: "pk_test_iHlOzpKZ00xvLmaK5VisiwVb00YvxyDFhZ"
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  ...config
};