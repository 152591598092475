import React, { Component } from "react";
import { FormGroup, FormControl, Checkbox } from "react-bootstrap";
import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./NewLink.css";

export default class NewNote extends Component {
  constructor(props) {
    super(props);

    

    this.state = {
      isLoading: null,
      content: "",
      description: "",
      used: false
    };
  }

  validateForm() {
    return this.state.content.length > 0;
  }

  handleChange = event => {
    if(event.target.name === "checkbox_used") {
      this.setState({
        used: !this.state.used
      })
      return;
    }
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  
  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await this.createLink({
        url: this.state.content,
        description: this.state.description,
        used: this.state.used
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }
  
  createLink(link) {
    return API.post("linkmailer", "/linkmailer", {
      body: link
    });
  }

  render() {
    return (
      <div className="NewNote">
        <form onSubmit={this.handleSubmit}>
          URL:
          <FormGroup controlId="content">
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="input"
            />
          </FormGroup>
          Description:
          <FormGroup controlId="description">
            <FormControl
              onChange={this.handleChange}
              value={this.state.description}
              componentClass="input"
            />
          </FormGroup>
        
          <FormGroup controlId="used">
            
            <Checkbox key="used" name="checkbox_used" onChange={this.handleChange} checked={this.state.used}>Used</Checkbox>
    
          </FormGroup>

          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}