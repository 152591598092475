import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import "./Home.css";
import { API } from "aws-amplify";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

library.add(faCheck)
export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      links: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
  
    try {
      const links = await this.links();
      this.setState({ links });
    } catch (e) {
      alert(e);
    }
  
    this.setState({ isLoading: false });
  }
  
  links() {
    return API.get("linkmailer", "/linkmailer");
  }

  renderLinkList(links) {
    var check = <FontAwesomeIcon icon="check" /> 
    return [{}].concat(links).map(
      (link, i) =>
        i !== 0
          ? <LinkContainer
              key={link.linkId}
              to={`/linkmailer/${link.linkId}`}
            >
              <ListGroupItem header={link.url.trim().split("\n")[0]}>
                {link.used?check:""}
              &nbsp;
              {link.description}
              </ListGroupItem>
            </LinkContainer>
          : <LinkContainer
              key="new"
              to="/linkmailer/new"
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> Create a new link
                </h4>
              </ListGroupItem>
            </LinkContainer>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>linkmailer</h1>
        <p>The perfect tool to grow your audience</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  renderLinks() {
    return (
      <div className="notes">
        <PageHeader>Your Links</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderLinkList(this.state.links)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderLinks() : this.renderLander()}
      </div>
    );
  }
}