import React, { Component } from "react";
import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { FormGroup, FormControl, Checkbox } from "react-bootstrap";
import "./Links.css"
export default class Links extends Component {
  constructor(props) {
    super(props);

    
    this.state = {
      link: null,
      url: "",
      used: false,
      description: "",
      isLoading: null,
      isDeleting: null
    };
  }

  async componentDidMount() {
    try {
      
      const link = await this.getLink();
      console.log(link);
      const { url, used, description} = link;

      

      this.setState({
        link,
        url,
        used, 
        description
        
      });
    } catch (e) {
      alert(e);
    }
  }

  getLink() {
    return API.get("linkmailer", `/linkmailer/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.url.length > 0;
  }
  

  
  handleChange = event => {
    if(event.target.name === "checkbox_used") {
      this.setState({
        used: !this.state.used
      })
      return;
    }
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  
  
  saveLink(link) {
    return API.put("linkmailer", `/linkmailer/${this.props.match.params.id}`, {
      body: link
    });
  }
  
  handleSubmit = async event => {
    event.preventDefault();
    
    this.setState({ isLoading: true });
  
    try {
     
  
      await this.saveLink({
        urlpath: this.state.url,
        used: this.state.used,
        description: this.state.description
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }
  
  deleteLink() {
    return API.del("linkmailer", `/linkmailer/${this.props.match.params.id}`);
  }
  
  handleDelete = async event => {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this link?"
    );
  
    if (!confirmed) {
      return;
    }
  
    this.setState({ isDeleting: true });
  
    try {
      await this.deleteLink();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }
  
  render() {
    return (
      <div className="Notes">
        {this.state.link &&
          <form onSubmit={this.handleSubmit}>
            URL:
            <FormGroup controlId="url">
              <FormControl
                onChange={this.handleChange}
                value={this.state.url}
                componentClass="input"
              />
            </FormGroup>
            Description:
            <FormGroup controlId="description">
              <FormControl
                onChange={this.handleChange}
                value={this.state.description}
                componentClass="input"
              />
            </FormGroup>
            Used:
            <FormGroup controlId="used">
            
            <Checkbox key="used" name="checkbox_used" onChange={this.handleChange} checked={this.state.used}>Used</Checkbox>
    
          </FormGroup>
           
            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving…"
            />
            <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              isLoading={this.state.isDeleting}
              onClick={this.handleDelete}
              text="Delete"
              loadingText="Deleting…"
            />
          </form>}
      </div>
    );
  }
}